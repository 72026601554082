var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("snackbar", {
        class: _vm.customClass,
        attrs: { "label-text": _vm.snackText, value: _vm.showSnack },
        on: { closed: _vm.closeSnack }
      }),
      _vm._v(" "),
      _c("main-nav", { attrs: { "nav-type": "dashboard" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "app-content" },
        [
          _c(
            "keep-alive",
            [_c(_vm.currentApp.component, { tag: "component" })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }