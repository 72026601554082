<template>
  <div class="container">
    <snackbar
      :class="customClass"
      :label-text="snackText"
      :value="showSnack"
      @closed="closeSnack"
    />
    <main-nav nav-type="dashboard"></main-nav>
    <div class="app-content">
      <keep-alive>
        <component :is="currentApp.component"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import MainNav from '../components/main-nav/main-nav.vue';
import Snackbar from 'gameon-components/src/components/snackbar/snackbar.vue';

import '../assets/icon-select-down-arrow.svg';

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import inspectlet from '../components/inspectlet/inspectlet';
import WHITELIST from '../components/inspectlet/whitelisted';

export default {
  components: {
    MainNav,
    Snackbar,
  },
  computed: {
    ...mapState('user', ['botData', 'data']),
    ...mapGetters('content', ['contentAvailable']),
    ...mapGetters('humanHandoff', ['humanHandoffAvailable']),
    ...mapState(['pipeOk', 'currentApp']),
    ...mapState('snackbar', ['customClass', 'showSnack', 'snackText']),
  },
  watch: {
    data() {
      const username = this.getId();
      if (username) {
        if (-1 < WHITELIST.indexOf(username)) {
          inspectlet();
        }
      }
    },

    // content
    async contentAvailable() {
      await this.initBotTagOptions();
      await this.initBotMeta();
    },
    // human handoff
    async humanHandoffAvailable() {
      if (this.humanHandoffAvailable) {
        this.updateChannelOptions();
      }
    },
  },
  methods: {
    ...mapActions('content', ['initBotTagOptions', 'initBotMeta']),
    ...mapActions('humanHandoff', ['updateChannelOptions']),
    ...mapMutations('snackbar', ['closeSnack']),
    getId() {
      const userLogged = this.data && this.data.username;
      if (userLogged && this.data.username) {
        return this.data.username;
      }
      return '';
    },
  },
  async mounted() {
    if (this.contentBots && this.contentBots.length) {
      await this.gatherViewFilterData();
      await this.initBotMeta();
    }

    // init analytics
    function gtag() {
      window.dataLayer.push(arguments);
    }
    if (window) {
      const analyticsScript = document.createElement('script');
      analyticsScript.setAttribute(
        'src',
        'https://www.googletagmanager.com/gtag/js?id=G-44PLECR216'
      );
      document.body.appendChild(analyticsScript);
      window.dataLayer = window.dataLayer || [];
      gtag('js', new Date());
      gtag('config', 'G-44PLECR216');
    }
  },
};
</script>

<style lang="scss">
@import '../styles/universal';
@import '../styles/fonts';

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: lighter;
}

.container {
  max-width: none;
  padding: 0;
  background: #fafafa;
}

.app-content {
  position: relative;
  height: 100%;
  max-width: 100%;
}

.menu--show + .app-content {
  max-width: calc(100% - 72px);
  margin-left: 72px;
}

.card-container {
  border-radius: 20px;
  width: 100%;
}

.card {
  line-height: 1;
  cursor: pointer;
  border-radius: 18px;
  &--text {
    min-height: 50px;
    padding: 16px;
    word-wrap: break-word;
    background-color: #eeeeee;
    &--small {
      min-height: 20px;
    }
  }
  &--placeholder {
    cursor: default;
    height: 50px;
    padding: 16px;
  }
  &--multi {
    .card--text {
      border-radius: 0 0 18px 18px;
      background-color: #fafafa;
    }
    .card--img {
      border-radius: 18px 18px 0 0;
    }
  }
}

.badge {
  img {
    cursor: pointer;
    position: absolute;
    z-index: 6;
    height: 24px;
    top: -10px;
    right: -16px;
  }
  &-inner {
    img {
      right: 16px;
    }
  }
}
</style>
